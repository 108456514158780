import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import Hero from '../components/hero'
import PlatformContent from '../components/platformContent'
import PlatformFeature from '../components/platformFeature'
import PlatformOverView from '../components/platformOverview'
import License from '../components/license'



class Platform extends Component {
    render() {
        if(!this.props.data.allContentfulTitle.edges) {
            return <div>Loading...</div>
        }
        const descriptionMeta = this.props.data.allContentfulTitle.edges[0].node.text
        return (
            <Layout>
                <SEO title="Platform - White Label" description={descriptionMeta} />
                <Hero position="Platform"
                    buttonText="Contact us"
                    classButton="platformCta"
                    bannerImage={true}
                    noBackgroundHero={true}
                    className="platformHero"
                    twoRows={true}>
                </Hero>
                <PlatformContent />
                <PlatformFeature />
                <PlatformOverView />
                <License />
            </Layout>
        )
    }
}

export default Platform

export const pageQuery = graphql`
    query PlatformPageQuery {
        allContentfulTitle(filter: {position: {eq: "Platform"}}) {
            edges {
                node {
                    text
                }
            }
        }
    }
`