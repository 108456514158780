import React, { useState, useEffect } from 'react'
import PlatformFeatureImage from './platformFeatureImage'
import PlatformFeatureList from './platformFeatureList'
import Title from '../components/title'
import {PlatformFeatureStyled} from './platformFeatureStyled'
import ScrollHook from './helper/scrollHook'



const PlatformFeature = () => {
    const [scrollToDiv, setScrollToDiv] = useState(false)
    const isScroll = ScrollHook()

    useEffect(() => {
        const list1= document.getElementsByClassName('list-1');
        const limit = list1[0].offsetTop - 700
        if(isScroll > limit) {
            setScrollToDiv(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isScroll])
    const list1Class = scrollToDiv ? `listContainer list-1 fadeInLeft` : `listContainer list-1`
    const list2Class = scrollToDiv ? `listContainer list-2 fadeInRight` : `listContainer list-2`
    return <PlatformFeatureStyled>
            <Title position="Platform" className="titleFeature" />
            <div className="container">
                <div className={list1Class}>
                    <PlatformFeatureList fromList={1} toList={3}/>
                </div>
                <PlatformFeatureImage />
                <div className={list2Class}>
                    <PlatformFeatureList fromList={4} toList={6}/>
                </div>
            </div>
        </PlatformFeatureStyled>
}


export default PlatformFeature
