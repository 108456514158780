import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'
import {bounceInUp, fadeInLeft, fadeInRight} from './helper/keyframes'


export const PlatformFeatureStyled = styled.div`
    padding: 5rem 15px;

    @media ${device.tablet} {
        padding: 8rem 15px;
    }


    .titleFeature {
        text-align: center;
        margin-bottom: 4rem;

        .underLine {
            margin: 0 auto;
        }

        .underLine.active {
            width: 30%;
        }
    }

    .container {
        max-width: ${width.xl};
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-flow: wrap;

        @media ${device.tablet} {
            flex-direction: row;
            flex-flow: nowrap;
        }
    }

    .listContainer {
        flex-basis: 30%;

        .listWrap {
            display: inline-block;
            border-bottom: 1px dotted ${palette.lightGreyLine};
            padding-bottom: 2rem;
            margin-bottom: 2rem;

            @media ${device.tablet} {
                padding-bottom: 0.5rem;
                margin-bottom: 1rem;
            }
        }

        .title {
            margin-bottom: 1rem;
            font-family: 'bebas-neue', sans-serif;
            font-size: ${palette.fontSizeReg};
            font-weight: ${palette.fontWeightTitle};
            text-transform: uppercase;
            font-weight: 500;

            @media ${device.tablet} {
                font-size: ${palette.fontSizeMd};
                margin-bottom: 0.3rem;
            }
        }

        .text {
            font-size: ${palette.fontSizeMd};
            line-height: ${palette.lineHeightSm};
            margin-bottom: 0;

            @media ${device.tablet} {
                font-size: ${palette.fontSizeXs};
                line-height: ${palette.lineHeightXs};
            }
        }

        &.list-1 {
            order: 2;
            flex-basis: 100%;
            text-align: center;
            opacity: 0;

            @media ${device.tablet} {
                order: 1;
                text-align: right;
            }

            &.fadeInLeft {
                opacity: 1;
                -webkit-animation: ${fadeInLeft} .5s linear;
                animation: ${fadeInLeft} .5s linear;
            }
        }

        &.list-2 {
            order: 3;
            flex-basis: 100%;
            text-align: center;
            opacity: 0;

            @media ${device.tablet} {
                order: 3;
                text-align: left;
            }

            &.fadeInRight {
                opacity: 1;
                -webkit-animation: ${fadeInRight} .5s linear;
                animation: ${fadeInRight} .5s linear;
            }
        }
    }

    .mainImage {
        flex-grow: 1;
        min-width: 100%;
        margin-bottom: 4rem;
        opacity: 0;

        @media ${device.tablet} {
            min-width: 450px;
            margin-bottom: 0;
            order: 2;
        }

        @media ${device.laptop} {
            min-width: 700px;
        }

        &.bounceIn {
            opacity: 1;
            -webkit-animation: ${bounceInUp} 1s linear;
            animation: ${bounceInUp} 1s linear;
        }

        /* .gatsby-image-wrapper {

            img {
                object-fit: contain !important;
            }
        } */
        img {
            object-fit: contain !important;
        }

    }
    img.icon {
        width: 80px;
        margin: 0 auto 2rem auto;
        @media ${device.tablet} {
            width: 60px;
            margin-bottom: 1rem;
            display: none;
        }
    }

`;

