import React from "react"
import PropTypes from 'prop-types'
import {ListStyled} from './listStyled'
import Markdown from 'react-markdown'


const ListWithIcon = ({html, className}) => {
    return <ListStyled className={className}>
          <div className="textWrap">
            {/* <div className="text" dangerouslySetInnerHTML={{
                __html: html,
            }}>

            </div> */}
            <Markdown source={html} className="text" />
          </div>
        </ListStyled>
}


ListWithIcon.propTypes = {
    className: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
}

 export default ListWithIcon

