import styled from 'styled-components'
import * as palette from '../styles/variables'

export const ListStyled = styled.div`
    .textWrap {
        margin-top: 3rem;
    }

    .text {
        padding-left: 0;
    }

    .text p {
        margin-bottom: 1rem;
        font-size: ${palette.fontSizeMd};
        line-height: ${palette.lineHeightSm};
        list-style-type: none;
    }
`;