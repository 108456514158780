import React, { useState, useEffect }  from 'react'
import { useStaticQuery, graphql } from "gatsby"
import ScrollHook from './helper/scrollHook'


const PlatformFeatureImage = () => {
  const [scrollToDiv, setScrollToDiv] = useState(false)
    const isScroll = ScrollHook()

    useEffect(() => {
        const mainImage = document.getElementsByClassName('mainImage');
        const limit = mainImage[0].offsetTop - 700
        if(isScroll > limit) {
            setScrollToDiv(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isScroll])

  const data = useStaticQuery(graphql`
  query PlatformFeatureImageQuery {
    allContentfulFeaturePlatformImage {
        edges {
          node {
            image {
              sizes(maxHeight: 1000, quality: 90, resizingBehavior: SCALE) {
                ...GatsbyContentfulSizes_withWebp
              }
              title
              file {
                url
              }
            }
            id
          }
        }
    }
  }
  `)
  const mainImageClass = scrollToDiv ? `mainImage bounceIn` : `mainImage`
  if(!data.allContentfulFeaturePlatformImage.edges) {
    return <div>Loading...</div>
  } else {
    return data.allContentfulFeaturePlatformImage.edges.map((edge) => {
      if(edge.node && edge.node.image) {
        return <div key={edge.node} className={mainImageClass}>
                  <img src={edge.node.image && edge.node.image.file.url} alt={edge.node.image.title} />
              </div>
      }
      return true;
    })
  }
}

export default PlatformFeatureImage
