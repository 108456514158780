import React from "react"
import PropTypes from 'prop-types'
import ButtonCta from './buttonCta'
import { Link } from "gatsby"
import ListWithIcon from './list'
import {ShortOverViewStyled, TextWrapStyled, TitleWrapStyled, TitleWrapMobileStyled,
  ButtonBarStyled, ShortOverViewWrapStyled,BgCasinoOverViewStyled} from './shortOverViewStyled'


const OverViewBlock = ({node, layout, html, className, video, backgroundImage}) => {
    return <ShortOverViewWrapStyled layout={layout} className={className}>
    {
      backgroundImage ?
      <BgCasinoOverViewStyled id="casinoVideo">
          {/* <Img alt={backgroundImage.file.fileName} sizes={backgroundImage.sizes} className="bgImg"/> */}
          <img src={backgroundImage && backgroundImage.file.url} alt={backgroundImage.file.fileName} className="bgImg" />
      </BgCasinoOverViewStyled>
      :
      null
      }
    <ShortOverViewStyled layout={layout} className="shortOverView" video={video} >
      <TitleWrapMobileStyled className={`${node.image ? 'has-img titleWrapMobile' : 'no-img titleWrapMobile'}`}>
        <h2 className="title">{node.title}</h2>
        {node.subTitle &&
          <p className="subTitle">{node.subTitle}</p>
        }
      </TitleWrapMobileStyled>
      <div className={`${node.image ? 'has-img imageWrap' : 'no-img imageWrap'}`}>
        {/* {video &&
        <React.Fragment>
          <img src={node.image.file.url} alt={node.title} className="previewImage" />
          <div className="previewVideo">
            <video autoPlay loop muted>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </React.Fragment>
        } */}
        {node.image &&
          <img src={node.image.file.url} alt={node.title} className="mainImg" />
        }
      </div>
      <TextWrapStyled layout={layout} className={`${node.image ? 'has-img' : 'no-img'}`}>
        <TitleWrapStyled>
          <h2 className="title">{node.title}</h2>
          {node.subTitle &&
            <p className="subTitle">{node.subTitle}</p>
          }
        </TitleWrapStyled>
        <ListWithIcon html={html}
        className={className}
        />
        <ButtonBarStyled layout={layout} className="buttonGroup">
          <Link to={`${node.linkTo}/` || '#'} className="overViewReadmore">Read more</Link>
          <ButtonCta text="contact us" className="overViewCta" />
        </ButtonBarStyled>
      </TextWrapStyled>
    </ShortOverViewStyled>
  </ShortOverViewWrapStyled>
}

OverViewBlock.propTypes = {
    node: PropTypes.object.isRequired,
    layout: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
}

 export default OverViewBlock

