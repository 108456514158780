import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {LicenseStyled} from './licenseStyled'



const License = () => {
    const data = useStaticQuery(graphql`
    query LicenseQuery {
        allContentfulLicense {
            edges {
                node {
                    title
                    text {
                        text
                        }
                    id
                }
            }
        }
    }
`)
if(!data.allContentfulLicense.edges) {
    return <div>Loading...</div>
} else {
    return data.allContentfulLicense.edges.map((edge) => {
        if(edge.node && edge.node.text) {
            return <LicenseStyled key={edge.node.id}>
                        <div className="container">
                            <h2 className="title">{edge.node.title}
                                <div className="underLine" />
                            </h2>
                            <p dangerouslySetInnerHTML={{
                                    __html: edge.node.text.text,
                                }}>
                            </p>
                        </div>
                    </LicenseStyled>
        }
        return true;
    })
}
}
 export default License

