import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device';



export const PlatformContentStyled = styled.div`
    background-image: none;
    background-color: ${palette.magenta};
    background-repeat: no-repeat;
    background-size: 100%;
    height: auto;

    @media ${device.laptop} {
        background-color: ${palette.white};
        background-image: url(${props => props.backgroundUrl ? props.backgroundUrl : ''});
    }

    .container {
        max-width: ${width.xl};
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 8rem;
        text-align: center;

        @media ${device.laptop} {
            text-align: right;
        }

    }

    img {
        max-width: 300px;
        margin: -50px auto auto auto;
        position: relative;

        @media ${device.laptop} {
            margin: -100px 4% auto auto;
        }

        -webkit-animation: float .5s normal ease-out;
        -moz-animation: float .5s normal ease-out;
        -o-animation: float .5s normal ease-out;
        animation: float .5s normal ease-out;

        @-webkit-keyframes float {
            0% {
                top:100px;
            }
            100% {
                top: 0px;
            }
        }
        @keyframes float {
            0% {
                opacity: 0;
                top:50px;
            }
            100% {
                opacity: 1;
                top: 0px;
            }
        }
    }


`;

