import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'



const PlatformFeatureList = ({fromList, toList}) => {
  const data = useStaticQuery(graphql`
  query PlatformFeatureListQuery {
    allContentfulFeaturePlatformList(sort: {fields: orderNumber}) {
        edges {
          node {
            id
            orderNumber
            text
            title
            icon {
                sizes(maxHeight: 200, quality: 90, resizingBehavior: THUMB) {
                    ...GatsbyContentfulSizes_withWebp
                }
                title
                file {
                  url
                }
            }
          }
        }
    }
  }
  `)

  if(!data.allContentfulFeaturePlatformList.edges) {
    return <div>Loading...</div>
  } else {
    return data.allContentfulFeaturePlatformList.edges.map((edge) => {
      if(edge.node && edge.node.icon && (edge.node.orderNumber >= fromList && edge.node.orderNumber <= toList)) {
        return <div className="listWrap" key={edge.node.id}>
            <img src={edge.node.icon && edge.node.icon.file.url} alt={edge.node.icon.title} className="icon"/>
            <h5 className="title">{edge.node.title}</h5>
            <p className="text">{edge.node.text}</p>
        </div>
      }
      return true;
    })
  }
}

PlatformFeatureList.propTypes = {
    fromList: PropTypes.number.isRequired,
    toList: PropTypes.number.isRequired,
}


export default PlatformFeatureList
