import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import OverViewBlock from './overViewBlock'
import {PlatformOverViewStyled} from './platformOverViewStyled'


const PlatformOverView = () => {
    const data = useStaticQuery(graphql`
    query PlatformOverViewQuery {
        allContentfulPlatformDetails(sort: {fields: orderNumber}) {
            edges {
              node {
                id
                subTitle
                title
                orderNumber
                detail {
                  detail
                }
                image {
                  sizes(maxHeight: 1000, quality: 90, resizingBehavior: SCALE) {
                    ...GatsbyContentfulSizes_withWebp
                  }
                  file {
                    fileName
                    url
                  }
                }
                className
              }
            }
        }
    }
    `)
    if(!data.allContentfulPlatformDetails.edges) {
        return <div>Loading...</div>
    } else {
        return data.allContentfulPlatformDetails.edges.map((edge, idx) => {
          if(edge.node && edge.node.detail && (edge.node.orderNumber < 100)) {
            const imageLayout = idx % 2 === 0 ? 'left' : 'left'
            const cName = edge.node.className ? `Platform ${edge.node.className}` : `Platform`
              return <PlatformOverViewStyled key={edge.node.id} id={`platformOverview-${idx}`}>
              <OverViewBlock node={edge.node}
                  layout={imageLayout}
                  html={edge.node.detail.detail}
                  className={cName}
                  />
              </PlatformOverViewStyled>
          }
          return true
        })

    }

}
 export default PlatformOverView

