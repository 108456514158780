import styled from 'styled-components'
import * as palette from '../styles/variables'


export const LicenseStyled = styled.div`
    background-image: linear-gradient(120deg,hsl(283,94%,29%),#1068ec);
    padding: 3rem 15px;
    color: ${palette.white};
    text-align: center;

    .title {
        font-weight: ${palette.fontWeightTitle};
        font-family: 'bebas-neue', sans-serif;
        font-size: ${palette.fontSizeXl};
        display: inline-block;
    }

    .underLine {
        padding-top: 1rem;
        width: 25%;
        border-bottom: 5px solid ${palette.white};
        margin: 0 auto;
    }

    p {
        margin-bottom: 0;
        line-height: ${palette.lineHeightMd};
    }

`;

