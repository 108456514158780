import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {PlatformContentStyled} from './platformContentStyled'



const PlatformContent = () => {
  const data = useStaticQuery(graphql`
  query PlatformContentQuery {
    allContentfulPlatformContent {
        edges {
          node {
            backgroundContent {
              file {
                url
                fileName
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
            id
            imageContent {
                sizes(maxHeight: 1000, quality: 90, resizingBehavior: SCALE) {
                    ...GatsbyContentfulSizes_withWebp
                }
                title
                file {
                  url
                }
            }
          }
        }
      }
  }
  `)

  if(!data.allContentfulPlatformContent.edges) {
    return <div>Loading...</div>
  } else {
    return data.allContentfulPlatformContent.edges.map((edge) => {
      if(edge.node && edge.node.backgroundContent && edge.node.backgroundContent.file &&
        edge.node.backgroundContent.file.details) {
            const url = edge.node.backgroundContent.file.url
        return <PlatformContentStyled key={edge.node.id} backgroundUrl={url}>
                    <div className="container">
                        {/* <Img alt={edge.node.imageContent.title} sizes={edge.node.imageContent.sizes} /> */}
                        <img src={edge.node.imageContent && edge.node.imageContent.file.url} alt={edge.node.imageContent.title} />
                    </div>
                </PlatformContentStyled>
      }
      return true;
    })
  }
}


export default PlatformContent
