import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'
import {fadeBlink} from './helper/keyframes'



export const ShortOverViewWrapStyled = styled.div`
    background-color: ${props => props.layout === 'left' ? palette.superDarkGrey : palette.grey};
    position: relative;

    .HomeList {
        .textWrap .text >p {
            color: ${palette.white};
            display: flex;

            &:before {
                font-family: 'Font Awesome 5 Free';
                content: '\f00c';
                color: ${palette.magenta};
                margin-right: 1em;
            }
        }
    }

    &.Poker {
        background-color: ${props => props.layout === 'left' ? palette.blueGrey : palette.superDarkGrey};

        .buttonGroup {
            padding-left: 0;
            padding-right: 0;

            .overViewReadmore {
            display: none;
            }
        }

        .title {
            color: ${props => props.layout === 'left' ? palette.black : palette.white};
            max-width: 100%;
            font-size: ${palette.fontSizeXl};

            @media ${device.tablet} {
                max-width: 50%;
            }
        }

        .subTitle {
            color: ${props => props.layout === 'left' ? palette.pinkGrey : palette.textDarkGrey};
            font-family: 'bebas-neue', sans-serif;
            font-size: ${palette.fontSizeLg};
            letter-spacing: 8px;
            transition: .7s ease-in-out;

            &.active {
                letter-spacing: 15px;
            }
        }

        .text {
            color: ${props => props.layout === 'left' ? palette.black : palette.white};
        }

    }

    &.Platform .sub {
        position: absolute;
        top: 0;
        width: 300px;
        overflow: hidden;
        display: none;

        @media ${device.laptop} {
            display: block;
        }

        .sub-2 {
            animation-name: ${fadeBlink};
            animation-duration: 8s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }
    }
    &.Platform {
        .title {
            margin-bottom: 0;
        }
    }

`;

export const ShortOverViewStyled = styled.div`
    max-width: ${width.xl};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 7rem;
    padding-bottom: 7rem;
    padding-left: 15px;
    padding-right: 15px;

    @media ${device.laptop} {
        flex-direction: row;
    }

    .imageWrap {
        width: 100%;
        height: 100%;
        max-width: none;
        margin: 0 auto;
        position: ${props => props.video ? 'relative' : 'static'};

        .previewVideo {
            display: none;

            @media ${device.laptop} {
                display: block;
            }

            video {
                width: 78%;
                position: absolute;
                top: 28px;
                left: 60px;
            }

        }

        .mainImg {
            margin: 0;
        }

        @media ${device.laptop} {
            max-width: 800px;
        }

    }

    @media ${device.laptop} {
        flex-direction: ${props => props.layout === 'left' ? 'row-reverse' : 'row'};
        align-items: center;
    }
`;

export const TextWrapStyled = styled.div`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    z-index: 1;

    @media ${device.laptop} {
        margin: ${props => props.layout === 'left' ? '0 100px 0 0' : '0 0 0 100px'};
    }
    &.no-img {
        margin-left: 0;
        margin-right: 0;
    }

    .title {
        margin-bottom: ${palette.lineHeightSm};
        font-size: ${palette.fontSizeXl};
        font-weight: ${palette.fontWeightTitle};
        font-family: 'bebas-neue', sans-serif;
        color: ${props => props.layout === 'left' ? palette.white : palette.white};
    }

    .subTitle {
        font-size: ${palette.fontSizeMd};
        color: ${palette.darkGrey};
        line-height: ${palette.lineHeightSm};
        color: ${props => props.layout === 'left' ? palette.white : palette.white};
    }
`;

export const ButtonBarStyled = styled.div`
    display: flex;
    justify-content: left;
    align-items: flex-start;
    padding-left: 1rem;
    padding-top: 2rem;
    flex-direction: column;
    padding-right: 15px;

    @media ${device.tablet} {
        flex-direction: row;
        align-items: center;
    }

    .overViewReadmore {
        color: ${props => props.layout === 'left' ? palette.white : palette.white};
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        margin-right: 2rem;
        margin-bottom: 2rem;

        @media ${device.tablet} {
            margin-bottom: 0;
        }
    }

    .overViewReadmore:hover {
        color: ${props => props.layout === 'left' ? palette.mediumGrey : palette.mediumGrey};
    }

    .overViewCta {
        width: 100%;

        @media ${device.tablet} {
            width: auto;
        }
    }
    .overViewCta a {
        width: 100%;
        max-width: 100%;

        @media ${device.tablet} {
            min-width: 250px;
        }
    }
`;


/*   Change Title Position between Desktop and Mobile  */

export const TitleWrapStyled = styled.div`
    display: none;

    @media ${device.laptop} {
        display: block;
    }
`

export const TitleWrapMobileStyled = styled.div`
    display: block;

    @media ${device.laptop} {
        display: none;
    }

    .title {
        color: ${palette.white};
        font-size: ${palette.fontSizeXl};
        font-weight: ${palette.fontWeightTitle};
        font-family: 'bebas-neue', sans-serif;
    }

    .subTitle {
        color: ${palette.white};
    }
`

export const BgCasinoOverViewStyled = styled.div`
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    .bgImg {
        max-width: ${width.xl};
        transition: .5s ease-in-out;
        margin: 0 auto;
        display: none;

        @media ${device.laptop} {
            display: block;
        }


        &:hover {
            transform: scale(1.1);
        }
    }
`;