import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device } from '../styles/device'
import bgGraphic from '../images/Nano_Logo_noedge.svg'


export const PlatformOverViewStyled = styled.div`
    .Platform {
        background-color: ${palette.white};

        .shortOverView {
            padding-top: 0;
        }

        .titleWrapMobile {
            margin-bottom: 0;

            &.no-img {
                margin-bottom: 0;
                .subTitle {
                    margin-bottom: 0;
                }
            }
        }
        .text {
            em {
                position: relative;
                top: -0.5em;
                font-size: 80%;
            }
        }
    }

    .title {
        color: ${palette.black};
        max-width: 100%;
        font-size: ${palette.fontSizeXl};

        @media ${device.tablet} {
            /* max-width: 50%; */
        }
    }

    .subTitle {
        color: ${palette.pinkGrey};
        font-family: 'bebas-neue', sans-serif;
        font-size: ${palette.fontSizeLg};
        letter-spacing: 8px;
        transition: .7s ease-in-out;

        &.active {
            letter-spacing: 15px;
        }
    }

    .overViewReadmore {
        display: none;
    }

    .buttonGroup {
        padding-left: 0;
        display: none;
    }

    .imageWrap {
        /* background-image: url(${bgGraphic}); */
        background-repeat: no-repeat;
        background-size: contain;
        &.no-img {
            width: unset;
        }
    }

    &#platformOverview-0 .imageWrap {
        background-image: none;
        background-position: right;
        padding: 0;
        max-width: 1400px;
        min-width: 300px;

        @media ${device.tablet} {
            /* background-image: url(${bgGraphic});
            padding: 50px 80px 50px 50px; */
        }
    }
    &#platformOverview-5 .imageWrap {
        background-image: none;
        background-position: right;
        padding: 0;
        max-width: 1400px;
        min-width: 300px;

        @media ${device.tablet} {
            background-image: url(${bgGraphic});
            padding: 50px 80px 50px 50px;
        }
    }
    &#platformOverview-6 .imageWrap {
        background-image: none;
        background-position: right;
        padding: 0;
        max-width: 1400px;
        min-width: 300px;

        @media ${device.tablet} {
            background-image: url(${bgGraphic});
            padding: 50px 80px 50px 50px;
        }
    }

    /* &#platformOverview-1 .imageWrap {
        background-position: center;
        padding: 0 100px 0 100px;
        max-width: 500px;
    } */
`;

